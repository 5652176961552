import {Injectable} from '@angular/core';
import {PathService} from "./path.service";
import {AnyMap, OkInfo} from "../util/interfaces";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

export interface DomainInfo {
    ok: boolean;
    regByAprilSea: boolean;
    usesPrimaryDns: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ForSaleService {

    private readonly forSaleRoot: string;

    constructor(private http: HttpClient,
                private pathService: PathService) {
        this.forSaleRoot = `${this.pathService.apiRoot}/forsale`;
    }

    getAllDomains(): Observable<string[]> {
        return this.http.get<AnyMap>(this.forSaleRoot).pipe(
            map((out) => out.domains)
        );
    }

    addDomain(domain: string): Observable<DomainInfo> {
        return this.http.post<DomainInfo>(this.forSaleRoot, {domain: domain});
    }

    removeDomain(domain: string): Observable<OkInfo> {
        return this.http.put<OkInfo>(this.forSaleRoot, {domain: domain});
    }

}
