import { Component, OnInit } from '@angular/core';
import {ValidatedForm} from "../../util/validated-form";
import {FormBuilder, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {AlertService} from "../../services/alert.service";
import {UtilService} from "../../services/util.service";
import {Constants} from "../../util/constants";
import {finalize} from "rxjs/operators";

const RESET_LINK_SENT_MSG = "If your email is registered, you'll soon receive a link<br/> to change you password.";

@Component({
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends ValidatedForm implements OnInit {

    constructor(protected  fb:FormBuilder,
                private authService: AuthService,
                private alertService: AlertService,
                private utilService: UtilService) {
        super(fb);
        this.initForm({
            email: {
                value: '',
                validators: [Validators.pattern(Constants.EMAIL_REGEX), Validators.required],
                messages: {pattern: Constants.ERR_INVALID_EMAIL, required: Constants.ERR_REQUIRED}
            }
        });
    }

    ngOnInit() {
    }

    submit() {
        this.submitting = true;
        this.authService.requestPasswordReset(this.fields.email.value).pipe(
            finalize(() => { this.submitting = false })
        ).subscribe(() => {
            this.alertService.info(RESET_LINK_SENT_MSG);
        }, this.utilService.getErrorHandler("Error requesting password reset"));
    }
}
