import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

/** Provides a button that lets user choose a certain local file, and once chosen it gets loaded into local memory
 * and its contents become accessible as a text string.
 */
@Component({
    selector: 'aa-file-load-button',
    templateUrl: './file-load-button.component.html',
    styleUrls: ['./file-load-button.component.css']
})
export class FileLoadButtonComponent implements OnInit {

    /** Theme color to use for the button: default use basic (background-like) color, just like mat-raised-button.
     */
    @Input() color: 'primary'|'accent'|'warn';
    /** What types of files to accept by default: a comma-separates list of file extensions (.jpg)
     *  and/or MIME types (image/gif) or generic media types (audio/*, video/* or image/*)
     */
    @Input() accept: string;
    @Input() disabled: boolean = false;

    /** Emitted when the file contents are loaded, providing the content string as the event */
    @Output() loaded: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('file_input', {static: true}) fileInput: ElementRef;

    constructor() {
    }

    ngOnInit() {
    }

    fileChanged() {
        let elem: HTMLInputElement = this.fileInput.nativeElement;

        // We only allow single file loading, so file should be first item in files list
        const file: File = elem.files[0];
        // Obtain the file contents as a string and notify listeners
        file.text().then((text) => {
            this.loaded.emit(text);
        });
        this.resetInput();
    }

    private resetInput() {
        // Must allow the file input element to forget its prior file(s), in case user wants to load the same
        // thing again. Otherwise, the change event doesn't trigger because nothing has changed. This seems to be
        // the cleanest way to achieve it.
        let elem: HTMLInputElement = this.fileInput.nativeElement;
        elem.type = "";
        elem.type = "file";
    }
}
