<h1>Domains For Sale</h1>

<mat-spinner [diameter]="30" *ngIf="loading"></mat-spinner>

<!--<div *ngIf="!loading">-->
<!--    <div class="domain_row" *ngFor="let domain of domains">{{domain}}</div>-->
<!--</div>-->

<div class="button_row" *ngIf="!loading">
    <input matInput placeholder="type domain name" [(ngModel)]="newDomain">
    <button mat-raised-button color="primary"
            [disabled]="!newDomain || submitting"
            (click)="addDomain()">
        + Add Domain
    </button>
    <mat-spinner [ngClass]="{hidden: !submitting}" [diameter]="30"></mat-spinner>
</div>

<div [hidden]="loading || !domains || domains.length==0" class="paginator_row">
    <mat-paginator #paginator
                   [length]="domains?.length"
                   [pageIndex]="0"
                   [pageSize]="10"
                   [pageSizeOptions]="[10, 20, 40]"></mat-paginator>
</div>
<table mat-table *ngIf="!loading && domains?.length" [dataSource]="domainRange">

    <ng-container matColumnDef="domain">
        <th mat-header-cell *matHeaderCellDef>Domain name</th>
        <td mat-cell *matCellDef="let domain" [innerHTML]="domain"></td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let domain">
            <button mat-icon-button
                    color="primary"
                    title="Delete"
                    (click)="removeDomain(domain)"><mat-icon>delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row
        *matRowDef="let domain; columns: columnsToDisplay"></tr>

</table>
