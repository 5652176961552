import {Injectable} from '@angular/core';
import {AlertService} from "./alert.service";

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    constructor(private alertService: AlertService) {
    }

    public getErrorHandler(defaultMessage: string = null): (err:any) => void {
        return (error:any) => {
            if (error.error instanceof Blob)  {
                error.error.text().then( text => {
                    const info = JSON.parse(text);
                    let message = info.message || defaultMessage || "Error";
                    this.alertService.error(message);
                });
            } else {
                let message = error.error && error.error.message || defaultMessage || "Error";
                this.alertService.error(message);
            }
        }
    }

    public setBodyBusy(flag: boolean) {
        if (flag)
            document.body.classList.add("busy");
        else
            document.body.classList.remove("busy");
    }
}
