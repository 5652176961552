import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {FileSaverService} from "ngx-filesaver";

@Component({
    templateUrl: './transaction-details-dialog.component.html',
    styleUrls: ['./transaction-details-dialog.component.css']
})
export class TransactionDetailsDialogComponent implements OnInit {

    readonly columnsToDisplayMin = ['domain', 'result', 'completed'];
    readonly columnsToDisplayWithAuth = ['domain', 'auth', 'result', 'completed'];
    readonly columnsToDisplayWithId = ['domain', 'dataId', 'result', 'completed'];

    @ViewChild('paginator') paginator: MatPaginator;
    actions: any[];
    transactionId: number;
    haveData: boolean;
    haveDataId: boolean;
    columnsToDisplay: string[];

    /** US timestamp format */
    private timestampFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-US', {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour12: true, hour: 'numeric', minute: '2-digit', second: '2-digit'
    });


    constructor(private dialogRef: MatDialogRef<TransactionDetailsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data,
                private cdRef: ChangeDetectorRef,
                private fileSaver: FileSaverService) {
        this.transactionId = this.data.transactionId;
        this.actions = this.data.actions;
        this.haveData = this.actions?.some((a) => !!a.data);
        this.haveDataId = this.actions?.some((a) => !!a.dataId);
        this.columnsToDisplay = this.columnsToDisplayMin;
        if (this.haveData)
            this.columnsToDisplay = this.columnsToDisplayWithAuth;
        else if (this.haveDataId)
            this.columnsToDisplay = this.columnsToDisplayWithId;
    }

    ngOnInit(): void {
        this.cdRef.detectChanges();
    }

    get actionRange(): any[] {
        if (!this.paginator)
            return [];
        const pageSize = this.paginator.pageSize;
        const offset = this.paginator.pageIndex * pageSize;
        return this.actions.slice(offset, offset + pageSize);
    }

    formatTimestamp(sd: string): string {
        return sd? this.timestampFormat.format(new Date(sd)) : "";
    }
    
    exportCsv() {
        // Build CSV as text
        let out:string;
        if (this.haveData) {
            out = '"Transaction ID","Domain Name","Auth Code","Result","Date Complete"\n';
            this.actions.forEach((a) => {
                const completed = this.formatTimestamp(a.completedDate);
                out += `"${this.transactionId}","${a.domainName}","${a.data}","${a.result}","${completed}"\n`
            });
        } else {
            out = '"Transaction ID","Domain Name","Result","Date Complete"\n';
            this.actions.forEach((a) => {
                const completed = this.formatTimestamp(a.completedDate);
                out += `"${this.transactionId}","${a.domainName}","${a.result}","${completed}"\n`
            });
        }
        this.fileSaver.saveText(out, "Report.csv", {
            type: "text/csv;charset=utf-8"
        })
    }

}
