import {Component, OnInit} from '@angular/core';
import {Contact, DomainService, NameserverGroup} from "../../services/domain.service";
import {UtilService} from "../../services/util.service";
import {finalize} from "rxjs/operators";
import {ValidatedForm} from "../../util/validated-form";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {Constants} from "../../util/constants";
import {Operation} from "../../util/operation";
import {AlertService} from "../../services/alert.service";
import {StateService} from "../../services/state.service";
import {RightType, Role, User} from "../../services/auth.service";

@Component({
    templateUrl: './operations.component.html',
    styleUrls: ['./operations.component.css']
})
export class OperationsComponent extends ValidatedForm implements OnInit {

    nameserverGroups: NameserverGroup[];
    contacts: Contact[];

    constructor(protected fb: FormBuilder,
                private domainService: DomainService,
                private alertService: AlertService,
                private state: StateService,
                private utilService: UtilService) {
        super(fb);
        this.debounceDelay = 100;
        this.initForm({
            op: { value: Operation.AvailabilityCheck },
            nameserverGroup: {
                value: -1,
                validators: [Validators.required],
                messages: {required: Constants.ERR_REQUIRED}
            },
            contact: {
                value: -1,
                validators: [Validators.required],
                messages: {required: Constants.ERR_REQUIRED}
            },
            domains: {
                value: '',
                validators: [Validators.required],
                messages: {required: Constants.ERR_REQUIRED}
            },
            notes: {
                value: ''
            }
        });
    }

    ngOnInit() {
        this.domainService.getNameservers().subscribe((groups) => {
            this.nameserverGroups = groups;
        });
        this.domainService.getContacts().subscribe((contacts) => {
            this.contacts = contacts;
        });
        this.form.controls.op.valueChanges.subscribe((newOpValue) => {
            const groupId = newOpValue == Operation.ChangeNameServers? '' : -1;
            const contactId = newOpValue == Operation.ChangeContactInfo? '' : -1;
            this.form.patchValue({nameserverGroup: groupId, contact: contactId});
        });
    }

    canUnlock() {
        const role: Role = this.state.get('role');
        return role && role.rights.find((r) => r.id==RightType.MANAGE_LEGAL_FLAG);
    }

    getOp(opName: string): Operation {
        const op = Operation[opName];
        if (!op)
            console.error("Invalid operation:", opName);
        return op;
    }

    needsNameservers(): boolean {
        return [Operation.ChangeNameServers, Operation.Create].includes(this.fields.op.value);
    }

    needsContacts(): boolean {
        return [Operation.ChangeContactInfo, Operation.Create].includes(this.fields.op.value);
    }

    csvLoaded(text: string) {
        this.form.patchValue({domains: text});
    }

    submit() {
        this.submitting = true;
        const op = this.fields.op.value;
        const domains = this.fields.domains.value.split("\n").map((dom:string) => dom.trim()).filter((d) => !!d);
        const notes = this.fields.notes?.value?.trim() || null;
        let dataId = null;
        let dataString = null;
        if (op == Operation.ChangeNameServers)
            dataId = this.fields.nameserverGroup.value;
        else if (op == Operation.ChangeContactInfo)
            dataId = this.fields.contact.value;

        this.domainService.queueOperation(op, domains, notes, dataId, dataString).pipe(
            finalize(() => {this.submitting=false})
        ).subscribe((info) => {
            this.alertService.info(`Operation queued successfully, with transaction ID: ${info.id}`);
            this.form.reset();
        }, this.utilService.getErrorHandler("Error queuing domain operation"));
    }

    clear() {
        this.form.reset();
    }
}
