import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FormBuilder, Validators} from "@angular/forms";
import {finalize} from "rxjs/operators";
import {ValidatedForm} from "../../util/validated-form";
import {AlertService} from "../../services/alert.service";
import {StateService} from "../../services/state.service";
import {UtilService} from "../../services/util.service";
import {AuthService} from "../../services/auth.service";
import {Constants} from "../../util/constants";
import {AppValidators} from "../../util/app-validators";
import {Passwords} from "../../util/passwords";

const MSG_PASSWORD_UPDATED = "The password was updated correctly";

@Component({
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends ValidatedForm implements OnInit {

    private resetToken: string;

    constructor(protected fb: FormBuilder,
                private route: ActivatedRoute,
                private alertService: AlertService,
                private state: StateService,
                private utilService: UtilService,
                private authService: AuthService) {
        super(fb);
        this.initForm({
            email: {
                value: '',
                validators: [Validators.pattern(Constants.EMAIL_REGEX), Validators.required],
                messages: {pattern: Constants.ERR_INVALID_EMAIL, required: Constants.ERR_REQUIRED}
            },
            password: {
                value: '',
                validators: [Validators.required, AppValidators.PasswordQualityValidator(Passwords.DEFAULT_OPTIONS)],
                messages: {
                    required: Constants.ERR_REQUIRED,
                    minLength: Constants.ERR_PASSWORD_LENGTH,
                    letters: Constants.ERR_PASSWORD_LETTERS_DIGITS,
                    digits: Constants.ERR_PASSWORD_LETTERS_DIGITS,
                    allowSpaces: Constants.ERR_PASSWORD_SPACES
                }
            },
            password2: {
                value: '',
                validators: [Validators.required],
                messages: {required: Constants.ERR_REQUIRED}
            }
        }, {
            validator: AppValidators.ControlMatchValidator('password', 'password2'),
            messages: {mismatch: Constants.ERR_PASSWORD_MISMATCH}
        });
    }

    ngOnInit() {
        this.resetToken = this.route.snapshot.params['token'];
    }

    getPasswordHint() {
        return Passwords.GetHint();
    }

    submit() {
        this.submitting = true;
        this.authService.resetPassword(this.fields.email.value, this.resetToken, this.fields.password.value).pipe(
            finalize(() => {
                this.submitting = false
            })
        ).subscribe(() => {
                this.alertService.infoObs(MSG_PASSWORD_UPDATED)
                    .subscribe(() => {
                        this.state.clearAll();
                        this.authService.redirectToLogIn();
                    });
            }, this.utilService.getErrorHandler("Error resetting password")
        );
    }
}
