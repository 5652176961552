import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AlertType} from "../../services/alert.service";

const DEFAULT_OK_LABEL = "OK";

@Component({
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {

    alertType: AlertType;
    title: string;
    message: string;
    okLabel: string;

    constructor(private dialogRef: MatDialogRef<AlertDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data) {
    }

    ngOnInit() {
        this.alertType = this.data.alertType;
        this.title = this.data.title? this.data.title : this.defaultTitle();
        this.message = this.data.message;
        this.okLabel = this.data.okLabel? this.data.okLabel : DEFAULT_OK_LABEL;
    }

    get iconType(): string {
        switch (this.alertType) {
            case 'error':
                return 'error';
            case 'warn':
                return 'warning';
            case 'info':
                return 'info';
            case 'debug':
                return 'bug_report';
        }
    }

    defaultTitle(): string {
        switch (this.alertType) {
            case 'error':
                return 'Error';
            case 'warn':
                return 'Warning';
            case 'info':
                return 'Information';
            case 'debug':
                return 'Debug';
        }
    }
}
