<h1>Transactions</h1>

<form *ngIf="users" [formGroup]="form" (ngSubmit)="submit()">
    <mat-form-field appearance="outline">
        <mat-label>Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker" [max]="today">
            <input matStartDate
                   placeholder="Start date"
                   required
                   formControlName="start">
            <input matEndDate
                   placeholder="End date"
                   required
                   formControlName="end">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>User</mat-label>
        <mat-select formControlName="user">
            <mat-option *ngFor="let user of users" [value]="user.id">
                {{user.email}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Operation</mat-label>
        <mat-select formControlName="operation">
            <mat-option *ngFor="let op of operations" [value]="op.id">
                {{op.description}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="button_row">
        <button mat-raised-button
                color="primary"
                type="submit"
                [disabled]="form.invalid || submitting">Search</button>
        <mat-spinner *ngIf="submitting" [diameter]="30"></mat-spinner>
    </div>
</form>
<mat-spinner *ngIf="!users"></mat-spinner>

<div [hidden]="submitting || !transactions || transactions.length==0" class="paginator_row">
    <mat-paginator #paginator
                   [length]="transactions?.length"
                   [pageIndex]="0"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
</div>
<table mat-table *ngIf="!submitting && transactions?.length" [dataSource]="transRange">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
        <td mat-cell *matCellDef="let trans" [innerHTML]="trans.id"></td>
    </ng-container>

    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef>User</th>
        <td mat-cell *matCellDef="let trans">{{trans.user.email}}</td>
    </ng-container>

    <ng-container matColumnDef="op">
        <th mat-header-cell *matHeaderCellDef>Operation</th>
        <td mat-cell *matCellDef="let trans">{{trans.operation}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let trans">{{status(trans)}}</td>
    </ng-container>

    <ng-container matColumnDef="started">
        <th mat-header-cell *matHeaderCellDef>Start Date</th>
        <td mat-cell *matCellDef="let trans">{{formatTimestamp(trans.startDate)}}</td>
    </ng-container>

    <ng-container matColumnDef="completed">
        <th mat-header-cell *matHeaderCellDef>Completed Date</th>
        <td mat-cell *matCellDef="let trans">{{formatTimestamp(trans.completedDate)}}</td>
    </ng-container>

    <ng-container matColumnDef="cancelled">
        <th mat-header-cell *matHeaderCellDef>Cancelled Date</th>
        <td mat-cell *matCellDef="let trans">{{formatTimestamp(trans.cancelledDate)}}</td>
    </ng-container>

    <ng-container matColumnDef="count">
        <th mat-header-cell *matHeaderCellDef>Domain Count</th>
        <td mat-cell *matCellDef="let trans">{{trans.domainCount}}</td>
    </ng-container>

    <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef>Notes</th>
        <td mat-cell *matCellDef="let trans">{{trans.notes}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let trans">
            <button mat-raised-button
                    class="detail_button"
                    color="primary"
                    (click)="showDetail(trans)">Detail</button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row
        *matRowDef="let score; columns: columnsToDisplay"></tr>

</table>


<!--<div *ngIf="transactions">-->
<!--    <div *ngFor="let t of transactions">-->
<!--        id: {{t.id}}, user: {{t.user.name}}, op: {{t.operation}}, dcount: {{t.domainCount}}, start: {{t.startDate}}, complete: {{t.completedDate}}-->
<!--    </div>-->
<!--</div>-->
