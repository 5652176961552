import {Component} from '@angular/core';
import {finalize} from "rxjs/operators";
import {AuthService} from "../../services/auth.service";
import {StateService} from "../../services/state.service";
import {Router} from "@angular/router";
import {UtilService} from "../../services/util.service";

@Component({
    selector: 'aa-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent {

    submitting: boolean = false;

    constructor(private authService: AuthService,
                private state: StateService,
                private utilService: UtilService,
                private router: Router) {
    }

    isLoggedIn(): boolean {
        return this.authService.isLoggedIn();
    }

    logout() {
        this.submitting = true;
        this.authService.logOut().pipe(
            finalize(() => {this.submitting = false})
        ).subscribe((info) => {
            this.state.clearAll();
            this.router.navigateByUrl('/login').then();
        }, this.utilService.getErrorHandler("error logging out"));
    }

}
