import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PathService} from "./path.service";
import {Observable} from "rxjs";
import {Operation} from "../util/operation";
import {AnyMap, IdInfo} from "../util/interfaces";
import {map} from "rxjs/operators";

export interface NameserverGroup {
    group_id: number;
    description: string;
}

export interface Contact {
    id: number;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class DomainService {

    readonly domainRoot: string;

    constructor(private http: HttpClient,
                private pathService: PathService) {
        this.domainRoot = `${this.pathService.apiRoot}/domain`;
    }

    // checkAvailability(domain: string): Observable<any> {
    //     return this.http.post<any>(`${this.domainRoot}/check`, {domains: [domain]});
    // }

    queueOperation(op: Operation, domains: string[],
                   notes: string=null, dataId: number=0, dataString: string=null) : Observable<IdInfo> {
        return this.http.post<IdInfo>(`${this.domainRoot}/queue`, {
            opId: op,
            domains: domains,
            notes: notes,
            dataId: dataId,
            dataString: dataString
        });
    }

    getNameservers(): Observable<NameserverGroup[]> {
        return this.http.get<AnyMap>(`${this.domainRoot}/nameservers`).pipe(
            map((a) => a.nameservers)
        );
    }

    getContacts(): Observable<Contact[]> {
        return this.http.get<AnyMap>(`${this.domainRoot}/contacts`).pipe(
            map((a) => a.contacts)
        );
    }
}
