
<h1>Log In</h1>

<form [formGroup]="form" (ngSubmit)="submit()">
    <mat-form-field>
        <input matInput
               formControlName="email"
               placeholder="Email">
        <mat-error>{{fields.email.errors}}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput
               formControlName="password"
               placeholder="Password"
               type="password">
        <mat-error>{{fields.email.errors}}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>2FA code</mat-label>
        <input matInput
               formControlName="code"
               placeholder="6-digit code">
        <mat-error>{{fields.code.errors}}</mat-error>
    </mat-form-field>

    <div class="button_row">
        <button mat-raised-button
                color="primary"
                [disabled]="form.invalid || submitting"
                type="submit">Log In</button>
        <mat-spinner *ngIf="submitting" [diameter]="30"></mat-spinner>
    </div>

    <div class="link" routerLink="/forgot-password">Forgot password?</div>

</form>
