import {Component} from '@angular/core';
import {ValidatedForm} from "../../util/validated-form";
import {FormBuilder} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {finalize} from "rxjs/operators";
import {StateService} from "../../services/state.service";
import {UtilService} from "../../services/util.service";

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent extends ValidatedForm {

    constructor(protected fb: FormBuilder,
                private router: Router,
                private state: StateService,
                private utilService: UtilService,
                private authService: AuthService) {
        super(fb);
        this.initForm({
            email: { value: '' },
            password: {  value: '' },
            code: { value: '' }
        });
    }

    submit() {
        this.submitting = true;
        const email = this.fields.email.value;
        const password = this.fields.password.value;
        const code = this.fields.code.value;
        this.authService.logIn(email, password, code).pipe(
            finalize(() => {
                this.submitting = false;
                this.state.clear('targetUrl');
            })
        ).subscribe(() => {
            const targetUrl = this.state.get('targetUrl') || '/';
            this.router.navigateByUrl(targetUrl).then();
        }, this.utilService.getErrorHandler("Login error"));
    }
}
