<span class="logo"
      routerLink="/">April Sea</span>
<button *ngIf="isLoggedIn() && !submitting"
        mat-raised-button
        color="primary"
        (click)="logout()">Log Out</button>
<mat-spinner *ngIf="submitting"
             [diameter]="30"></mat-spinner>


