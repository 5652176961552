<h1>Domain Operations</h1>

<h2>Select domain operation:</h2>

<form [formGroup]="form"
      class="wrapper"
      (ngSubmit)="submit()">
    <div class="ops">
        <mat-radio-group formControlName="op">
            <mat-radio-button [value]="getOp('ChangeNameServers')">Change nameservers</mat-radio-button>
            <mat-radio-button [value]="getOp('ChangeContactInfo')">Change contacts</mat-radio-button>
            <mat-radio-button [value]="getOp('Create')">Register domains</mat-radio-button>
<!--            <mat-radio-button [value]="getOp('Delete')">Delete domains</mat-radio-button>-->
            <mat-radio-button [value]="getOp('SyncFromRegistry')">Sync registry info</mat-radio-button>
            <mat-radio-button [value]="getOp('AvailabilityCheck')">Availability check</mat-radio-button>
            <mat-radio-button [value]="getOp('SubmitTransfer')">Submit transfer</mat-radio-button>
            <mat-radio-button [value]="getOp('CompleteTransfer')">Complete transfer</mat-radio-button>
            <mat-radio-button [value]="getOp('ApproveTransfer')">Approve transfer</mat-radio-button>
            <mat-radio-button [value]="getOp('Unlock')"
                              [disabled]="!canUnlock()">Unlock and get auth codes</mat-radio-button>
            <mat-radio-button [value]="getOp('Lock')"
                              [disabled]="!canUnlock()">Lock</mat-radio-button>
            <mat-radio-button [value]="getOp('BulkRenew')">Bulk renew domains</mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="right">
        <div [ngClass]="{hidden: !needsNameservers()}">
            <mat-spinner *ngIf="!nameserverGroups" [diameter]="30"></mat-spinner>
            <mat-form-field *ngIf="nameserverGroups">
                <mat-label>Nameserver Group</mat-label>
                <mat-select formControlName="nameserverGroup"
                            required>
                    <mat-option value="">(Select Nameserver Group)</mat-option>
                    <mat-option *ngFor="let group of nameserverGroups"
                                [value]="group.id">{{group.description}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div [ngClass]="{hidden: !needsContacts()}">
            <mat-spinner *ngIf="!contacts" [diameter]="30"></mat-spinner>
            <mat-form-field *ngIf="contacts">
                <mat-label>Contact</mat-label>
                <mat-select formControlName="contact"
                            required>
                    <mat-option value="">(Select Contact)</mat-option>
                    <mat-option *ngFor="let contact of contacts"
                                [value]="contact.id">{{contact.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>Domains</mat-label>
            <textarea matInput
                      id="domains_area"
                      required
                      formControlName="domains"></textarea>
            <mat-hint>Enter one or more domains, one per line</mat-hint>
        </mat-form-field>

        <div class="hint">Or load the domain list from a CSV file</div>
        <aa-file-load-button (loaded)="csvLoaded($event)"
                             color="primary"
                             [disabled]="submitting"
                             accept=".csv">Load Domains</aa-file-load-button>

        <mat-form-field id="notes_field"
                        appearance="outline">
            <mat-label>Notes</mat-label>
            <textarea matInput
                      id="notes_area"
                      formControlName="notes"></textarea>
            <mat-hint>Any information related to this operation</mat-hint>
        </mat-form-field>

        <div class="button_row">
            <button mat-raised-button
                    color="primary"
                    type="submit"
                    [disabled]="submitting || form.invalid">Queue Operation</button>
            <button mat-raised-button
                    type="button"
                    [disabled]="submitting"
                    (click)="clear()">Clear</button>
            <mat-spinner *ngIf="submitting" [diameter]="30"></mat-spinner>
        </div>

    </div>
</form>
