import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from "./pages/login/login.component";
import {TransactionsComponent} from "./pages/transactions/transactions.component";
import {MainComponent} from "./pages/main/main.component";
import {AuthenticatedGuard} from "./guards/authenticated.guard";
import {OperationsComponent} from "./pages/operations/operations.component";
import {ForSaleComponent} from "./pages/for-sale/for-sale.component";
import {ForgotPasswordComponent} from "./pages/forgot-password/forgot-password.component";
import {ResetPasswordComponent} from "./pages/reset-password/reset-password.component";


const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent},
    { path: 'reset-password/:token', component: ResetPasswordComponent },
    { path: '', component: MainComponent, canActivate: [AuthenticatedGuard] },
    { path: 'transactions', component: TransactionsComponent, canActivate: [AuthenticatedGuard] },
    { path: 'operations', component: OperationsComponent, canActivate: [AuthenticatedGuard] },
    { path: 'for-sale', component: ForSaleComponent, canActivate: [AuthenticatedGuard] }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
