
<h1>Change Password</h1>

<form [formGroup]="form" (ngSubmit)="submit()">
    <div>
        <mat-form-field>
            <input matInput
                   placeholder="Email"
                   required
                   formControlName="email">
            <mat-error>{{fields.email.errors}}</mat-error>
        </mat-form-field>
    </div>
    <div>
        <aa-password-input label="Password"
                           [required]="true"
                           [hint]="getPasswordHint()"
                           [errors]="fields.password.errors"
                           control_name="password"></aa-password-input>
    </div>
    <div>
        <aa-password-input id="password2_field"
                           label="Confirm password"
                           [required]="true"
                           [errors]="fields.password2.errors"
                           control_name="password2"></aa-password-input>
    </div>
    <div class="error" [innerHTML]="formInfo.errors"></div>
    <div id="button_row">
        <button mat-raised-button
                class="large_button"
                type="submit"
                color="primary"
                [disabled]="!form.valid || submitting">Change Password</button>
    </div>
</form>
<mat-spinner *ngIf="submitting"></mat-spinner>
