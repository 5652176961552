import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";


const DEFAULT_TITLE = "Confirmation";
const DEFAULT_QUESTION = "Are you sure?";
const DEFAULT_YES_ANSWER = "Yes";
const DEFAULT_NO_ANSWER = "No";

@Component({
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

    title: string;
    question: string;
    yesAnswer: string;
    noAnswer: string;

    constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data) {
    }

    ngOnInit() {
        this.title = this.data.title? this.data.title : DEFAULT_TITLE;
        this.question = this.data.question? this.data.question : DEFAULT_QUESTION;
        this.yesAnswer = this.data.yesAnswer? this.data.yesAnswer : DEFAULT_YES_ANSWER;
        this.noAnswer = this.data.noAnswer? this.data.noAnswer : DEFAULT_NO_ANSWER;
    }

    answer(flag:boolean) {
        this.dialogRef.close(flag);
    }
}
