<h1 mat-dialog-title>
    Transaction Details
</h1>
<div class="export_row">
    <button mat-raised-button
            color="accent"
            (click)="exportCsv()">Export to CSV</button>
</div>
<div class="paginator_row">
    <span class="trans_id">Transaction ID: {{transactionId}}</span>
    <mat-paginator #paginator
                   [length]="actions.length"
                   [pageIndex]="0"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
</div>
<mat-dialog-content>
    <div class="mat-body-1">
        <table mat-table [dataSource]="actionRange">
            <ng-container matColumnDef="domain">
                <th mat-header-cell *matHeaderCellDef>Domain Name</th>
                <td mat-cell *matCellDef="let a">{{a.domainName}}</td>
            </ng-container>
            <ng-container *ngIf="haveData"
                          matColumnDef="auth">
                <th mat-header-cell *matHeaderCellDef>AuthCode</th>
                <td mat-cell *matCellDef="let a">{{a.data || ''}}</td>
            </ng-container>
            <ng-container *ngIf="haveDataId"
                          matColumnDef="dataId">
                <th mat-header-cell *matHeaderCellDef>Data ID</th>
                <td mat-cell class="center" *matCellDef="let a">{{a.dataId || ''}}</td>
            </ng-container>
            <ng-container matColumnDef="result">
                <th mat-header-cell *matHeaderCellDef>Result</th>
                <td mat-cell *matCellDef="let a">{{a.result || ''}}</td>
            </ng-container>
            <ng-container matColumnDef="completed">
                <th mat-header-cell *matHeaderCellDef>Date Complete</th>
                <td mat-cell *matCellDef="let a">{{formatTimestamp(a.completedDate)}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row
                *matRowDef="let score; columns: columnsToDisplay"></tr>

        </table>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button
            mat-dialog-close
            color="primary"
            type="button">OK</button>
</mat-dialog-actions>
