<button mat-raised-button
        type="button"
        (click)="file_input.click()"
        [disabled]="disabled || uploading"
        [color]="color">
    <ng-content></ng-content>
</button>
<input type="file"
       #file_input
       [multiple]="multiple"
       [accept]="accept"
       [disabled]="disabled || uploading"
       (change)="fileChanged()"/>
