<mat-form-field>
    <mat-label *ngIf="label">{{label}}</mat-label>
    <input matInput
           #input
           [required]="required"
           [autofocus]="autofocus"
           [type]="getType()"
           [placeholder]="placeholder"
           [formControlName]="controlName">
    <mat-icon matSuffix
              (click)="toggleVisibility()"
              [title]="getTooltip()">{{getIcon()}}</mat-icon>
    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
    <mat-error>{{errors}}</mat-error>
</mat-form-field>
