import {Injectable} from '@angular/core';
import {AnyMap} from '../util/interfaces';

/** Persistent state storage of any kind of value associated to any string key
 */
@Injectable({
    providedIn: 'root'
})
export class StateService {

    private state: AnyMap = {};

    constructor() {
        this.hydrate();
    }

    get(key: string): any {
        return this.state[key];
    }

    set(key: string, value: any) {
        this.state[key] = value;
        this.persist();
    }

    clear(...keys: string[]) {
        keys.forEach((key) => {
            delete this.state[key];
        });
        this.persist();
    }

    clearAll() {
        this.state = {};
        localStorage.removeItem('state');
    }

    setMany(map: AnyMap) {
        Object.keys(map).forEach((key:string) => {
            this.state[key] = map[key];
        });
        this.persist();
    }

    private persist() {
        localStorage.setItem('state', JSON.stringify(this.state))
    }

    private hydrate() {
        const saved = localStorage.getItem('state');
        if (saved)
            this.state = JSON.parse(saved);
    }
}
