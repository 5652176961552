
import {AbstractControl, FormGroup} from "@angular/forms";
import {AnyMap} from './interfaces';

/** Specifies the quality features a valid password should have
 */
export interface PasswordQualityOptions {
    minLength: number;
    letters: boolean;
    maxLength?: number;
    mixedCase?: boolean;
    digits?: boolean;
    specialChars?: boolean;
    allowSpaces?: boolean;
}

/** Collection of useful form control validators
 */
export class AppValidators {

    /** Returns an isBlank flag if the control value is just composed of spaces
     *
     * @param control
     * @returns {AnyMap} with isBlank flag, or null if no issue found
     */
    public static BlankValidator(control: AbstractControl): AnyMap {
        const text = control.value;
        if (!text || !text.match(/^\s+$/))
            return null;
        return { isBlank: true };
    }

    /** Generates a validator function that knows how to compare two controls from a given FormGroup and return null
     * if they match, or {mismatch: true} if they don't.
     *
     * @param controlName1 {string} the name of the first control to compare
     * @param controlName2 {string} the name of the second control to compare
     * @param filter {Function} filters both control's values transforming them into some simplified form before comparing (optional)
     * @returns {(fg:FormGroup)=>{mismatch: boolean}}
     */
    public static ControlMatchValidator(controlName1: string, controlName2: string, filter?: (text:string) => string): (fg: FormGroup) => AnyMap {
        return (fg: FormGroup) => {
            let v1: string = fg.get(controlName1).value;
            let v2: string = fg.get(controlName2).value;
            if (filter) {
                v1 = filter(v1);
                v2 = filter(v2);
            }
            return v1 === v2 ? null : { mismatch: true };
        };
    }

    /** Generates a validator function that validates a control's text value to ensure it satisfies the password
     * quality restrictions given to ita s options. The validator function itself returns null in case no violation
     * is found, or an object with a single flag set to true, depending on which condition was violated first from
     * the list: minLength, letters, maxLength, mixedCase, digits, specialChars or allowSpaces
     *
     * @param options
     * @returns {(control:AbstractControl) => AnyMap}
     */
    public static PasswordQualityValidator(options: PasswordQualityOptions): (control: AbstractControl) => AnyMap {
        return (control: AbstractControl) => {
            const value: string = control.value;
            if (value === undefined || value === null)
                return null;
            if (options.minLength && value.length < options.minLength)
                return {minLength: true};
            if (options.letters && !value.match(/[a-zA-Z]/))
                return {letters: true}
            if (options.maxLength && value.length > options.maxLength)
                return {maxLength: true};
            if (options.mixedCase && (!value.match(/[a-z]/) || !value.match(/[A-Z]/)))
                return {mixedCase: true};
            if (options.digits && !value.match(/\d/))
                return {digits: true};
            if (options.specialChars && !value.match(/[^a-zA-Z0-9]/))
                return {specialChars: true};
            if (!options.allowSpaces && value.match(/\s/))
                return {allowSpaces: true};
            return null;
        };
    }
}
