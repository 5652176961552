import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {AuthService} from "../services/auth.service";

/** Makes sure there is a currently active user session (as of last interaction with server)
 */
@Injectable()
export class AuthenticatedGuard implements CanActivate {

    constructor(private authService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (!this.authService.isLoggedIn()) {
            this.authService.redirectToLogIn(state.url);
            return false;
        }
        return true;
    }
}
