import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType} from "@angular/common/http";
import {concatMap, finalize} from "rxjs/operators";
import {from as observableFrom} from "rxjs";
import {OkInfo} from "../../util/interfaces";
import {AlertService} from "../../services/alert.service";
import {UtilService} from "../../services/util.service";

const DEFAULT_MAX_BATCH_BYTES = 20*1024*1024;
const DEFAULT_MAX_BATCH_FILES = 25;

export interface UploadInfo {
    totalFiles: number;
    totalBytes: number;
    firstFile: string;
    lastFile: string;
}

export interface UploadProgress {
    loadedFiles: number;
    totalFiles: number;
    loadedBytes: number;
    totalBytes: number;
}

@Component({
    selector: 'aa-upload-button',
    templateUrl: './upload-button.component.html',
    styleUrls: ['./upload-button.component.css']
})
export class UploadButtonComponent implements OnInit {

    /** API URL to witch we should post uploaded files */
    @Input() url: string;
    @Input() color: 'primary'|'accent'|'warn';
    @Input() multiple: boolean = false;
    @Input() accept: string;
    @Input() alias: string = "upload";
    @Input() path: string;
    @Input() disabled: boolean = false;
    @Input('new_file_name') newFileName: string;
    @Input('create_last_dir') createLastDir: boolean = false;
    @Input('max_batch_bytes') maxBatchBytes: number = DEFAULT_MAX_BATCH_BYTES;
    @Input('max_batch_files') maxBatchFiles: number = DEFAULT_MAX_BATCH_FILES;

    @Output() start: EventEmitter<UploadInfo> = new EventEmitter<UploadInfo>();
    @Output() progress: EventEmitter<UploadProgress> = new EventEmitter<UploadProgress>();
    @Output() done: EventEmitter<UploadInfo> = new EventEmitter<UploadInfo>();

    @ViewChild('file_input', {static: true}) fileInput: ElementRef;

    uploading: boolean;
    private batches: File[][];

    constructor(private http:HttpClient,
                private alertService: AlertService,
                private utilService: UtilService) {
    }

    ngOnInit() {
    }

    private resetBatches() {
        this.batches = [[]];
    }

    private batchSize(b: File[]): number {
        return b.map((f:File) => f.size).reduce((total:number, size:number) => total+size, 0);
    }

    private addToBatch(f: File) {
        // Verify file by itself doesn't exceed max upload limit
        if (f.size > this.maxBatchBytes)
            throw new Error(`File ${f.name} exceeds max upload size`);
        // Access last batch
        let b: File[] = this.batches[this.batches.length-1];
        // Check if adding this file to batch would exceed max. If so, add new batch and switch to it
        const currentTotal = this.batchSize(b);
        if (currentTotal + f.size > this.maxBatchBytes) {
            this.batches.push([]);
            b = this.batches[this.batches.length-1];
        }
        // Add the file
        b.push(f);
        // If batch reached max # of files, add a new batch
        if (b.length >= this.maxBatchFiles)
            this.batches.push([]);
    }

    fileChanged() {
        let elem: HTMLInputElement = this.fileInput.nativeElement;

        // Group FileList into a list of file batches
        this.resetBatches();
        const files: FileList = elem.files;
        let info: UploadInfo = {
            totalBytes: 0,
            totalFiles: 0,
            firstFile: files[0].name,
            lastFile: files[files.length-1].name
        };
        try {
            for (let k = 0; k < files.length; ++k) {
                this.addToBatch(files[k]);
                info.totalFiles++;
                info.totalBytes += files[k].size;
            }
        } catch (error) {
            this.alertService.error(error.message);
            return;
        } finally {
            this.resetInput();
        }

        // console.log(`Using ${this.batches.length} batches:`);
        // for (let b of this.batches)
        //     console.log(`  files: ${b.length}, size: ${this.batchSize(b)} bytes`);

        let key = `${this.alias}[]`;

        this.start.emit(info);

        let progress: UploadProgress = {
            totalBytes: info.totalBytes,
            totalFiles: info.totalFiles,
            loadedBytes: 0,
            loadedFiles: 0
        };
        let newProgress: UploadProgress;

        const firstBatch = this.batches[0];
        const lastBatch = this.batches[this.batches.length-1];
        let currentBatch;
        // Map each batch to an observable, then process each sequentially via concatMap.
        // For each batch post all its files and emit events as applicable
        observableFrom(this.batches).pipe(
            concatMap((b:File[]) => {
                this.uploading = true;
                let reqBody = new FormData();
                reqBody.append('path', this.path);
                if (b === firstBatch) {
                    // This only makes sense for single files, so only for first batch
                    if (this.newFileName)
                        reqBody.append('new_file_name', this.newFileName);

                    // This makes sense only for first batch, further batches will already have the dir created
                    if (this.createLastDir)
                        reqBody.append('create_last_dir', '1');
                }
                for (let f of b)
                    reqBody.append(key, f);
                currentBatch = b;
                newProgress = Object.assign({}, progress);
                return this.http.post<OkInfo>(this.url, reqBody, {reportProgress: true, observe: 'events'});
            }),
            finalize(() => {this.uploading = false})
        ).subscribe((ev:HttpEvent<OkInfo>) => {
            if (ev.type == HttpEventType.UploadProgress) {
                // report progress
                newProgress.loadedBytes = progress.loadedBytes + ev.loaded;
                if (ev.loaded == ev.total)
                    newProgress.loadedFiles = progress.loadedFiles + currentBatch.length;
                // console.log("progress:", newProgress);
                // console.log(`current batch - files: ${currentBatch.length}, size: ${this.batchSize(currentBatch)} bytes`);
                this.progress.emit(newProgress);
            } else if (ev.type == HttpEventType.Response) {
                progress = newProgress;
                newProgress = Object.assign({}, progress);
                // we're done, with resulting response contents in ev.body
                // If this is the last batch, then report we're done!
                if (currentBatch === lastBatch)
                    this.done.emit(info);
            }
        }, this.utilService.getErrorHandler("Upload error"));
    }

    private resetInput() {
        // Must allow the file input element to forget its prior file(s), in case user wants to upload the same
        // thing again. Otherwise, the change event doesn't trigger because nothing has changed. This seems to be
        // the cleanest way to achieve it.
        let elem: HTMLInputElement = this.fileInput.nativeElement;
        elem.type = "";
        elem.type = "file";
    }
}
