import {Injectable} from '@angular/core';
import {PathService} from "./path.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class TransService {

    private readonly transRoot: string;

    constructor(private http: HttpClient,
                private pathService: PathService) {
        this.transRoot = `${this.pathService.apiRoot}/trans`;
    }

    search(startDate: string, endDate: string, userId: number=null, operationId: number=null): Observable<any> {
        return this.http.post<any>(`${this.transRoot}/search`, {
            startDate: startDate, endDate: endDate, userId: userId, operationId: operationId
        }).pipe(
            map((out) => out.transactions)
        );
    }
    
}
