<h1 mat-dialog-title> <button id="question_mark" mat-mini-fab color="warn">?</button> {{title}}</h1>
<mat-dialog-content>
    <div class="mat-body-1" [innerHTML]="question"></div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button
            color="warn"
            type="button"
            (click)="answer(true)">{{yesAnswer}}</button>
    <button mat-raised-button
            color="primary"
            type="button"
            (click)="answer(false)">{{noAnswer}}</button>
</mat-dialog-actions>
