<h1>Forgot Password</h1>
<div id="info">Enter your email and we will send you a link to change your password</div>
<form *ngIf="isFormReady()" [formGroup]="form" (ngSubmit)="submit()">
  <div>
    <mat-form-field>
      <input matInput
             required
             placeholder="Email"
             formControlName="email">
      <mat-error>{{fields.email.errors}}</mat-error>
    </mat-form-field>
  </div>
  <div id="button_row">
    <button mat-raised-button
            class="button"
            type="submit"
            color="primary"
            [disabled]="!form.valid || submitting">Send Link</button>
    <button mat-raised-button
            class="button"
            type="button"
            [disabled]="submitting"
            routerLink="/login">Cancel</button>
    <mat-spinner *ngIf="submitting" id="spinner"></mat-spinner>
  </div>
</form>

