import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {OkInfo} from "../util/interfaces";
import {from, Observable} from "rxjs";
import {PathService} from "./path.service";
import {map, tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {StateService} from "./state.service";

export interface User {
    id: number;
    email: string;
    name: string;
    role: Role;
}

export interface Session {
    token: string;
    email: string;
    role: Role;
    idle: number;
}

export interface Role {
    id: number;
    description: string;
    rights: Right[];
}

export interface Right {
    id: number;
    description: string;
}

export enum RightType {
    NORMAL_UPDATE_RIGHT = 1,
    BULK_DELETE_DOMAINS = 2,
    CATEGORIZATION = 3,
    DOMAIN_LOCKING = 4,
    ASSIGN_DOMAIN_LOCK_ADMIN = 5,
    ASSIGN_DOMAIN_LOCK_UDRP = 6,
    ASSIGN_DOMAIN_LOCK_LEGAL = 7,
    ASSIGN_DOMAIN_LOCK_REV = 8,
    REMOVE_DOMAIN_LOCK_ADMIN = 9,
    REMOVE_DOMAIN_LOCK_UDRP = 10,
    REMOVE_DOMAIN_LOCK_LEGAL = 11,
    REMOVE_DOMAIN_LOCK_REV = 12,
    ACCESS_SEM_WEB_SERVICES = 14,
    MANAGE_DOMAIN_FLAGS = 15,
    MANAGE_LEGAL_FLAG = 16,
    CREATE_NEW_USERS = 17,
    VIEW_ROLLIN_PROTECTION = 18
}


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private readonly authRoot: string;

    constructor(private http: HttpClient,
                private router: Router,
                private state: StateService,
                private pathService: PathService) {
        this.authRoot = `${this.pathService.apiRoot}/auth`;
    }

    logIn(email: string, password: string, code: string): Observable<Session> {
        return this.http.post<Session>(`${this.authRoot}/login`,
                                {email: email, password: password, code: code}).pipe(
            tap((session) =>  {
                this.state.setMany(session);
            })
        );
    }

    logOut(): Observable<OkInfo> {
        return this.http.put<OkInfo>(`${this.authRoot}/logout`, {});
    }

    getAllUsers(): Observable<any[]> {
        return this.http.get<any>(`${this.authRoot}/users`).pipe(
            map((out) => out.users as any[])
        );
    }

    isLoggedIn(): boolean {
        return !!this.state.get('token');
    }

    /** Redirects user to login page, remembering what target URL the user really wanted to reach, so they get sent
     * to it after login, if successful
     *
     * @param targetUrl (optional) where to go after login
     */
    redirectToLogIn(targetUrl?:string) {
        from(this.router.navigateByUrl('/login')).subscribe(() => {
            this.state.set('targetUrl', targetUrl);
        });
    }

    /** Requests that an email be sent to the given address with a link to a page where the user can set a new password.
     * It fails if the email address does not correspond to a valid user.
     *
     * @param userEmail the user's email
     */
    requestPasswordReset(userEmail: string): Observable<OkInfo> {
        return this.http.post<OkInfo>(`${this.authRoot}/request_password_reset`, {email: userEmail});
    }

    /** Changes the password for the user linked to the given reset token to the new given password string
     *
     * @param userEmail the user's email
     * @param token reset token received from system to validate this action
     * @param newPassword the new desired password
     */
    resetPassword(userEmail: string, token: string, newPassword: string): Observable<any> {
        return this.http.post<OkInfo>(`${this.authRoot}/reset_password`,
                                {email: userEmail, token: token, password: newPassword});
    }

}
