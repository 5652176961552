<h1 mat-dialog-title>
    <mat-icon id="{{alertType}}" class="alert_icon">{{iconType}}</mat-icon>
    <span>{{title}}</span>
</h1>
<mat-dialog-content>
    <div class="mat-body-1" [innerHTML]="message"></div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button
            mat-dialog-close
            color="primary"
            type="button">{{okLabel}}</button>
</mat-dialog-actions>
