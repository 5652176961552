import {Inject, Injectable, Injector, PLATFORM_ID} from '@angular/core';
import {DOCUMENT} from '@angular/common';

/** Provides information about the current URL that works well in Angular Universal also. It also provides
 * immutable info about the current site's root and other URLs.
 */
@Injectable({
    providedIn: 'root'
})
export class PathService {
    private static DEV_ORIGIN = "http://watney/as_admin";
    private static HOME_DOMAIN = ".maprilis.net";
    private static STAGING_HOST_NAME = "staging" + PathService.HOME_DOMAIN;
    private static STAGING_ORIGIN = "https://" + PathService.STAGING_HOST_NAME;
    private static LIVE_HOST_NAME = "admin" + PathService.HOME_DOMAIN;
    private static LIVE_ORIGIN = "https://" + PathService.LIVE_HOST_NAME;

    /** The host name used to access the current page */
    private readonly host: string;
    private readonly origin:string;
    private _siteRoot: string = null;


    /** Initializes service
     *
     * @param document
     * @param injector
     * @param platformId
     */
    constructor(@Inject(DOCUMENT) private document: Document,
                private injector: Injector,
                @Inject(PLATFORM_ID) private platformId: Object) {
        // if (isPlatformServer(this.platformId)) {
        //     const request = this.injector.get(REQUEST);
        //     this.host = request.get('x-forwarded-host');
        // } else
        this.host = this.document.location.host;
        if (this.host === PathService.LIVE_HOST_NAME)
            this.origin = PathService.LIVE_ORIGIN;
        else if (this.host === PathService.STAGING_HOST_NAME)
            this.origin = PathService.STAGING_ORIGIN;
        else
            this.origin = `http://${this.host}`;
    }

    /** Returns the absolute URL for the root of the site
     *
     * @returns {string}
     */
    get siteRoot(): string {
        if (!this._siteRoot) {
            // If we're running from elsewhere (such as localhost): use the default origin
            if (!this.origin.endsWith(PathService.HOME_DOMAIN))
                this._siteRoot = PathService.DEV_ORIGIN;
            // Otherwise use same origin as current page (this will include https schema if applicable
            else
                this._siteRoot = this.origin;
        }
        return this._siteRoot;
    }

    /** Returns the absolute URL for the root of our own API URLs
     *
     * @returns {string} the URL
     */
    get apiRoot(): string {
        return this.siteRoot + '/api';
    }

    /** Returns the absolute URL for the image used for meta tags
     *
     * @returns {string} the URL
     */
    get commonMetaImage(): string {
        return this.imageUrl("meta.jpg");
    }

    /** Returns the absolute URL for an asset image given path relative to the assets/image directory
     *
     * @param relPath
     */
    imageUrl(relPath:string): string {
        return `${this.siteRoot}/assets/images/${relPath}`;
    }

    /** Checks whether the app is currently running on the live site (as opposed to a local test, or staging site)
     *
     * @returns {boolean} true if on live site, false otherwise
     */
    isLiveSite(): boolean {
        return this.host === PathService.LIVE_HOST_NAME;
    }
}
