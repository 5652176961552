import {Component, OnInit, ViewChild} from '@angular/core';
import {ForSaleService} from "../../services/for-sale.service";
import {finalize, mergeMap} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {Constants} from "../../util/constants";
import {AlertService} from "../../services/alert.service";
import {UtilService} from "../../services/util.service";
import {DomainService} from "../../services/domain.service";
import {Operation} from "../../util/operation";
import {of} from "rxjs";

const PRIMARY_DNS_GROUP_NAME = "dpns.dnsnameserver.org";

@Component({
    templateUrl: './for-sale.component.html',
    styleUrls: ['./for-sale.component.css']
})
export class ForSaleComponent implements OnInit {

    readonly columnsToDisplay = ['domain', 'actions'];

    loading: boolean = false;
    domains: string[] = [];
    newDomain: string;
    submitting: boolean = false;

    @ViewChild('paginator') paginator: MatPaginator;
    
    constructor(private forSaleService: ForSaleService,
                private domainService: DomainService,
                private utilService: UtilService,
                private alertService: AlertService) {
    }

    ngOnInit() {
        this.loadDomains();
    }

    private loadDomains() {
        this.loading = true;
        this.forSaleService.getAllDomains().pipe(
            finalize(() => { this.loading = false })
        ).subscribe((domains) => {
            this.domains = domains;
        })
    }

    get domainRange() {
        if (!this.paginator)
            return [];
        const pageSize = this.paginator.pageSize;
        const offset = this.paginator.pageIndex * pageSize;
        return this.domains.slice(offset, offset + pageSize);
    }

    private showBusy(flag: boolean) {
        this.submitting = flag;
        this.utilService.setBodyBusy(flag);
    }

    addDomain() {
        const domain = this.newDomain.toLowerCase();
        // Validate new domain to add
        if (!domain.match(Constants.DOMAIN_REGEX))
            this.alertService.error(`Not a valid domain name: <b>${domain}</b>`);
        else if (this.domains.includes(domain))
                this.alertService.error(`Domain <b>${domain}</b> already for sale`);
        else {
            // Assume we own the domain and add it to for-sale list
            this.showBusy(true);
            this.forSaleService.addDomain(domain).pipe(
                finalize(() => {
                    this.showBusy(false);
                })
            ).subscribe((info) => {
                if (!info.ok)
                    this.alertService.error("Error analyzing domain");
                else if (!info.regByAprilSea) {
                    if (!info.usesPrimaryDns) {
                        this.alertService.warnObs(
                                `Registrar for domain <b>${domain}</b> is NOT April Sea, and it's using DNS servers other 
                                than our primary DNS servers. You must change its name servers using its registrar's site for 
                                the domain to actually show as For Sale.`)
                            .subscribe(() => {
                                this.domainAddSuccess(domain, false, true);
                            });
                    }
                } else if (!info.usesPrimaryDns) {
                    // It is registered with April Sea but with wrong DNS server group: change it!
                    // First get nameserver group list and find the primary DNS group in it.
                    // Then queue this domain to change its nameservers to primary group
                    this.showBusy(true);
                    this.domainService.getNameservers().pipe(
                        mergeMap((groups) => {
                            const primaryGroup = groups.find((g) => g.description == PRIMARY_DNS_GROUP_NAME);
                            if (primaryGroup)
                                return this.domainService.queueOperation(Operation.ChangeNameServers,
                                    [domain], 'now for sale', primaryGroup.group_id);
                            else
                                return of(null);
                        }),
                        finalize(() => {
                            this.showBusy(false);
                        })
                    ).subscribe((out) => {
                        this.domainAddSuccess(domain, true);
                    }, this.utilService.getErrorHandler("Error retrieving DNS server groups"));
                } else
                    this.domainAddSuccess(domain);

            }, this.utilService.getErrorHandler("Error adding domain"));
        }
    }

    private domainAddSuccess(domain: string, dnsUpdated: boolean = false, dnsUpdateNeeded: boolean = false) {
        this.newDomain = '';
        let msg: string = `Domain ${domain} successfully added.`;
        if (dnsUpdated)
            msg += "<br/>DNS server settings had to be updated to use primary servers.";
        if (dnsUpdateNeeded)
            msg += "Note that this won't take effect until after you change DNS settings via external registrar.";
        else
            msg += "<br/>Note it may take a few minutes for this change to become active.";
        this.alertService.info(msg);
        this.loadDomains();
    }

    removeDomain(domain: string) {
        this.alertService.confirm(`Are you sure you want to delete domain <b>${domain}</b> from for-sale list?`)
            .subscribe((answer) => {
                if (answer) {
                    this.showBusy(true);
                    this.forSaleService.removeDomain(domain).pipe(
                        finalize(() => {
                            this.showBusy(false);
                        })
                    ).subscribe((out) => {
                        this.alertService.info(`Domain ${domain} successfully deleted`);
                        this.loadDomains();
                    }, this.utilService.getErrorHandler("Error deleting domain"));
                }
            });
    }

}
