import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {AnyMap} from "../../util/interfaces";
import {finalize} from "rxjs/operators";
import {TransService} from "../../services/trans.service";
import {ValidatedForm} from "../../util/validated-form";
import {FormBuilder} from "@angular/forms";
import {StateService} from "../../services/state.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatDialog} from "@angular/material/dialog";
import {TransactionDetailsDialogComponent} from "../../dialogs/transaction-details-dialog/transaction-details-dialog.component";
import {Operation} from "../../util/operation";
import {UtilService} from "../../services/util.service";

const MIN_WIDTH: number = 640;
const MAX_WIDTH: number = 800;

@Component({
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent extends ValidatedForm implements OnInit {

    readonly operations: AnyMap[] = [
        {id: 0, description: 'ALL'},
        {id: Operation.ChangeNameServers, description: "Change nameservers"},
        {id: Operation.ChangeContactInfo, description: "Change contact info"},
        {id: Operation.Create, description: "Create"},
        {id: Operation.Delete, description: "Delete"},
        {id: Operation.AvailabilityCheck, description: "Availability check"},
        {id: Operation.SyncFromRegistry, description: "Sync database from registry"},
        {id: Operation.SubmitTransfer, description: "Submit Transfer"},
        {id: Operation.Unlock, description: "Unlock"},
        {id: Operation.Lock, description: "Lock"},
        {id: Operation.CompleteTransfer, description: "Complete Transfer"},
        {id: Operation.PrivacyInfo, description: "Privacy Info"},
        {id: Operation.ApproveTransfer, description: "Approve Transfer"},
        {id: Operation.BulkRenew, description: "Bulk Renew"},
        {id: Operation.BulkRestore, description: "Bulk Restore"},
        {id: Operation.BulkRestoreReport, description: "Bulk Restore Report"}
    ];

    users: AnyMap[];
    transactions: any[];
    submitting: boolean;
    today: Date = new Date();
    /** Format that produces SQL compatible dates: yyyy-mm-dd */
    dateFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat('sv-SE', {year: 'numeric', month: '2-digit', day: '2-digit'});

    /** US timestamp format */
    timestampFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-US', {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour12: true, hour: 'numeric', minute: '2-digit', second: '2-digit'
    });

    readonly columnsToDisplay = ['id', 'user', 'op', 'status', 'started', 'completed', 'cancelled', 'count', 'notes', 'actions'];

    @ViewChild('paginator') paginator: MatPaginator;


    constructor(protected fb: FormBuilder,
                private authService: AuthService,
                private transService: TransService,
                private state: StateService,
                private utilService: UtilService,
                private cdRef: ChangeDetectorRef,
                private dialog: MatDialog) {
        super(fb);
        this.initForm({
            start: { value: this.today },
            end: {value: this.today} ,
            user: {value: 0},
            operation: {value: 0}
        });
    }

    ngOnInit() {
        this.users = this.state.get('users');
        if (!this.users) {
            this.authService.getAllUsers().subscribe((users) => {
                this.users = [{id: 0, email: "ALL"}].concat(users);
                this.state.set('users', this.users);
            }, this.utilService.getErrorHandler("error getting users"));
        }
    }

    get transRange() {
        if (!this.paginator)
            return [];
        const pageSize = this.paginator.pageSize;
        const offset = this.paginator.pageIndex * pageSize;
        return this.transactions.slice(offset, offset + pageSize);
    }

    formatTimestamp(sd: string): string {
        return sd? this.timestampFormat.format(new Date(sd)) : "";
    }

    status(trans: any): string {
        if (!trans.completedDate)
            return "Processing";
        if (!trans.cancelledDate)
            return "Completed";
        return "Cancelled";
    }

    showDetail(trans: any) {
        this.dialog.open(TransactionDetailsDialogComponent, {
            minWidth: MIN_WIDTH,
            maxWidth: MAX_WIDTH,
            data: {
                transactionId: trans.id,
                actions: trans.actions
            }
        });
    }

    submit() {
        const start = this.dateFormat.format(this.fields.start.value);
        const end = this.dateFormat.format(this.fields.end.value);
        const userId = this.fields.user.value || null;
        const opId = this.fields.operation.value || null;
        this.submitting = true;
        this.transService.search(start, end, userId, opId).pipe(
            finalize(() => {this.submitting=false})
        ).subscribe((transactions) => {
            this.transactions = transactions;
            this.cdRef.detectChanges();
        }, this.utilService.getErrorHandler("error getting transactions"));
    }

}
