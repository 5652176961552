import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlContainer, FormGroupDirective} from '@angular/forms';
import {timer} from 'rxjs';

@Component({
    selector: 'aa-password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./password-input.component.css'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective
        }
    ]
})
export class PasswordInputComponent implements OnInit {

    @ViewChild('input') input: ElementRef;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() required: boolean;
    @Input() autofocus: boolean;
    @Input('control_name') controlName: string;
    @Input() hint: string;
    @Input() errors: string;


    visible: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

    focus() {
        timer(100).subscribe(() => {
            this.input.nativeElement.focus()
        });
    }

    getType(): string {
        return this.visible ? 'text' : 'password';
    }

    toggleVisibility() {
        this.visible = !this.visible;
    }

    getIcon(): string {
        return this.visible ? 'visibility_off' : 'visibility';
    }

    getTooltip(): string {
        return this.visible ? 'Hide password' : 'Show password';
    }
}
